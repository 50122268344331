import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import BookingRulesServices from 'src/services/BookingRules';

export const getBookingRules = createAsyncThunk(
	'bookingrates/get',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const result = await BookingRulesServices.get();

			dispatch({
				type: 'bookingrates/setBookingRates',
				payload: result.map((item) => ({
					...item,
					key: item.id,
				})),
			});

			return result;
		} catch (error) {
			rejectWithValue(error);
		}
	},
);

export const createBookingRule = createAsyncThunk(
	'bookingrates/create',
	async (payload, { dispatch, rejectWithValue, getState }) => {
		const bookingRates = getState().bookingrates.bookingRates;

		try {
			const result = await BookingRulesServices.create(payload);

			dispatch({
				type: 'bookingrates/setBookingRates',
				payload: bookingRates.push({
					...result,
					key: result.id,
				}),
			});

			return result;
		} catch (error) {
			rejectWithValue(error);
		}
	},
);

export const updateBookingRule = createAsyncThunk(
	'bookingrates/update',
	async (payload, { rejectWithValue }) => {
		try {
			const result = await BookingRulesServices.update(payload);
			return result;
		} catch (error) {
			rejectWithValue(error);
		}
	},
);

export const deleteBookingRule = createAsyncThunk(
	'bookingrates/delete',
	async (payload, { dispatch, rejectWithValue, getState }) => {
		const bookingRates = getState().bookingrates.bookingRates;

		try {
			await BookingRulesServices.delete(payload);

			dispatch({
				type: 'bookingrates/setBookingRates',
				payload: bookingRates.filter((item) => item.id !== payload),
			});
		} catch (error) {
			rejectWithValue(error);
		}
	},
);

export const bookingRatesSlice = createSlice({
	name: 'bookingrates',
	initialState: {
		loading: false,
		bookingRates: [],
		error: null,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getBookingRules.pending, (state) => {
				state.loading = true;
			})
			.addCase(getBookingRules.fulfilled, (state, action) => {
				state.loading = false;
				state.bookingRates = action.payload;
			})
			.addCase(getBookingRules.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(createBookingRule.pending, (state) => {
				state.loading = true;
			})
			.addCase(createBookingRule.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(createBookingRule.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(updateBookingRule.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateBookingRule.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(updateBookingRule.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
	reducers: {
		setBookingRates(state, action) {
			state.boardingRates = action.payload;
		},
	},
});

export const { setBookingRates } = bookingRatesSlice.actions;

export default bookingRatesSlice.reducer;

export const selectBookingRates = (state) =>
	state.bookingrates.boardingRates || [];
