import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Drawer, Space, Button, Popconfirm } from 'antd';
import {
	PlusCircleOutlined,
	EditOutlined,
	DeleteOutlined,
} from '@ant-design/icons';
import {
	getBookingRules,
	selectBookingRates,
	deleteBookingRule,
} from 'src/redux/bookingrates';
import CreateEditBookingRule from 'src/components/CreateEditBookingRule';

const BookingRules = () => {
	const dispatch = useDispatch();
	const bookingRates = useSelector(selectBookingRates);

	const [createEditOpen, setCreateEditOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);

	const fetchBookingRules = useCallback(async () => {
		try {
			await dispatch(getBookingRules());
		} catch (error) {
			console.error('Error fetching booking rules:', error);
		}
	}, []);

	useEffect(() => {
		fetchBookingRules();
	}, [fetchBookingRules]);

	const onRowClick = (item) => {
		setSelectedItem(item);
		setCreateEditOpen(true);
	};

	const onDrawerClose = () => {
		setSelectedItem(null);
		setCreateEditOpen(false);
		fetchBookingRules();
	};

	return (
		<div>
			<Space style={{ float: 'right', marginBottom: 16 }}>
				<Button
					type="primary"
					onClick={() => setCreateEditOpen(true)}
					style={{ background: '#008E87' }}>
					<PlusCircleOutlined /> New Booking Rule
				</Button>
			</Space>

			<Table
				dataSource={bookingRates}
				columns={[
					{
						title: 'ID',
						dataIndex: 'id',
					},
					{
						title: 'Reservation ID',
						dataIndex: 'resId',
					},
					{
						title: 'Days Between Reservations',
						dataIndex: 'daysBetweenReservations',
						align: 'center',
					},
					{
						title: 'Restricted Per Pet',
						dataIndex: 'restrictedPerPet',
						align: 'center',
						render: (val) => {
							return <p>{val ? 'Yes' : 'No'}</p>;
						},
					},
					{
						title: 'Action',
						render: (_, row) => {
							return (
								<>
									<Button
										onClick={() => onRowClick(row)}
										style={{ margin: '5px' }}>
										<EditOutlined />
									</Button>

									<Popconfirm
										title="Please Confirm"
										description="Are you sure to delete this?"
										onConfirm={async () => {
											try {
												await dispatch(deleteBookingRule(row.id));
												fetchBookingRules();
											} catch (error) {
												console.error('Error deleting booking rule:', error);
											}
										}}
										okText="Yes"
										cancelText="No">
										<Button>
											<DeleteOutlined />
										</Button>
									</Popconfirm>
								</>
							);
						},
					},
				]}
				pagination={false}
			/>

			<Drawer
				title={selectedItem ? 'Edit Booking Rule' : 'Create Booking Rule'}
				placement="right"
				size={'large'}
				width={900}
				onClose={onDrawerClose}
				open={createEditOpen}
				extra={
					<Space>
						<Button onClick={onDrawerClose}>Cancel</Button>
						<Button
							type="primary"
							form={selectedItem ? 'update-bookingrule' : 'create-bookingrule'}
							htmlType="submit">
							{selectedItem ? 'Update' : 'Create'}
						</Button>
					</Space>
				}>
				{createEditOpen && (
					<CreateEditBookingRule
						bookingRule={selectedItem}
						onClose={onDrawerClose}
					/>
				)}
			</Drawer>
		</div>
	);
};

export default BookingRules;
