import Network from './Network';

class BookingRulesServices {
	static async get() {
		let response = await Network.request({
			method: 'GET',
			url: '/admin/bookingRules',
		});

		return response.data;
	}

	static async create(payload) {
		let response = await Network.request({
			method: 'POST',
			url: '/admin/bookingRules',
			data: payload,
		});

		return response.data;
	}

	static async update({ id, ...payload }) {
		delete payload.id;

		let response = await Network.request({
			method: 'PUT',
			url: `/admin/bookingRules/${id}`,
			data: payload,
		});

		return response.data;
	}

	static async delete(id) {
		let response = await Network.request({
			method: 'DELETE',
			url: `/admin/bookingRules/${id}`,
		});

		return response.data;
	}
}

export default BookingRulesServices;
