/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Select } from 'antd';
const { Option } = Select;
import { createBookingRule, updateBookingRule } from 'src/redux/bookingrates';

const CreateEditBookingRule = ({ bookingRule, onClose }) => {
	const dispatch = useDispatch();

	const [form] = Form.useForm();

	const handleSubmit = async (values) => {
		try {
			await dispatch(
				bookingRule
					? updateBookingRule({
							...values,
							id: bookingRule.id,
							restrictedPerPet: Boolean(values.restrictedPerPet === 'true'),
					  })
					: createBookingRule({
							...values,
							restrictedPerPet: Boolean(values.restrictedPerPet === 'true'),
					  }),
			);
			onClose?.();
		} catch (error) {
			console.error('Error creating/editing booking rule:', bookingRule, error);
		}
	};

	return (
		<div>
			<Form
				form={form}
				id={bookingRule ? 'update-bookingrule' : 'create-bookingrule'}
				name={bookingRule ? 'update-bookingrule' : 'create-bookingrule'}
				onFinish={handleSubmit}
				style={{
					maxWidth: 880,
				}}
				initialValues={
					bookingRule
						? {
								resId: bookingRule.resId,
								daysBetweenReservations: bookingRule.daysBetweenReservations,
								restrictedPerPet: bookingRule.restrictedPerPet
									? 'true'
									: 'false',
						  }
						: undefined
				}
				scrollToFirstError>
				<Form.Item
					name="resId"
					label="Reservation ID"
					tooltip="Please enter the Reservation ID."
					rules={[
						{
							required: true,
							message: 'Please enter the Reservation ID',
						},
					]}>
					<Input />
				</Form.Item>

				<Form.Item
					name="daysBetweenReservations"
					label="Days Between Reservations"
					tooltip="Please enter the number of days between reservations."
					rules={[
						{
							required: true,
							message: 'Please enter the number of days between reservations.',
						},
					]}>
					<Input type="number" />
				</Form.Item>

				<Form.Item
					name="restrictedPerPet"
					label="Restricted Per Pet"
					rules={[
						{
							required: true,
							message: 'Please select at least one Restricted Per Pet',
						},
					]}>
					<Select placeholder="Enable or Disable?">
						<Option value="true">Yes</Option>
						<Option value="false">No</Option>
					</Select>
				</Form.Item>
			</Form>
		</div>
	);
};

export default CreateEditBookingRule;
